import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext, StateOperator, StateToken} from "@ngxs/store";
import {catchError, finalize, of, retry, tap} from "rxjs";
import {ProductsHttpService} from "../api/api/products.http.service";
import {Category} from "../api/model/category";
import {convertError, RequestError, ResetErrors} from "./common";
import {ProductManagementHttpService} from "../api/api/productManagement.http.service";
import {isPlatformBrowser} from "@angular/common";
import {nonNull} from "../services/util";

export interface CategoryStateModel {
  categories: Category[] | undefined;
  loading: boolean;
  error: RequestError | undefined;
}

export class LoadCategories {
  static readonly type = '[Categories] Load categories';
}

export class CreateCategory {
  static readonly type = '[Categories] Create category';

  constructor(
    readonly name: string,
    readonly description: string,
  ) {
  }
}

export class DeleteCategory {
  static readonly type = '[Categories] Delete category';

  constructor(
    readonly id: string,
  ) {
  }
}

export class ReorderCategories {
  static readonly type = '[Categories] Change order';

  constructor(
    readonly ids: string[],
  ) {
  }
}

export const CATEGORY_STATE_TOKEN = new StateToken<CategoryStateModel>('categoryState');

const addCategory: ((category: Category) => StateOperator<CategoryStateModel>) = (category) => {
  return (state: Readonly<CategoryStateModel>) => {
    return {
      ...state,
      categories: [
        ...state.categories || [],
        category
      ]
    }
  }
}

const removeCategory: ((id: string) => StateOperator<CategoryStateModel>) = (id) => {
  return (state: Readonly<CategoryStateModel>) => {
    return {
      ...state,
      categories: state.categories?.filter(elem => elem.id !== id)
    }
  }
}

@State({
  name: CATEGORY_STATE_TOKEN,
  defaults: {
    categories: undefined,
    error: undefined,
    loading: false,
  }
})
@Injectable()
export class CategoryState {

  constructor(
    private readonly productsService: ProductsHttpService,
    private readonly productManagementService: ProductManagementHttpService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
  ) {
  }

  @Selector()
  static categories(state: CategoryStateModel) {
    return state.categories;
  }

  static byName(name: string): (state: CategoryStateModel) => Category | undefined {
    return createSelector([CategoryState], (state: CategoryStateModel) => {
      return state.categories?.find(c => c.name === name)
    });
  }

  @Selector()
  static loading(state: CategoryStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: CategoryStateModel) {
    return state.error;
  }

  @Action(ResetErrors, {cancelUncompleted: true})
  resetErrors(ctx: StateContext<CategoryStateModel>) {
    ctx.patchState({error: undefined})
  }

  @Action(LoadCategories, {cancelUncompleted: true})
  loadCategories(ctx: StateContext<CategoryStateModel>, action: LoadCategories) {
    return this.productsService.getCategories().pipe(
      isPlatformBrowser(this.platformId) ? retry({delay: 10000}) : nonNull(),
      tap(categories => ctx.patchState({categories})),
      catchError(err => {
        console.error('Failed loading categories.', err)
        return of([])
      }),
    )
  }

  @Action(CreateCategory, {cancelUncompleted: false})
  createCategory(ctx: StateContext<CategoryStateModel>, action: CreateCategory) {
    ctx.patchState({
      error: undefined,
      loading: true,
    })

    return this.productManagementService.createCategory({
      writeCategoryRequest: {
        name: action.name,
        description: action.description
      }
    }).pipe(
      tap({
        next: category => ctx.setState(addCategory(category)),
        error: error => ctx.patchState({error: convertError(error)}),
      }),
      finalize(() => ctx.patchState({loading: false})),
    );
  }

  @Action(DeleteCategory, {cancelUncompleted: false})
  deleteCategory(ctx: StateContext<CategoryStateModel>, action: DeleteCategory) {
    ctx.patchState({
      error: undefined,
      loading: true,
    })

    return this.productManagementService.deleteCategory({categoryId: action.id}).pipe(
      tap({
        next: () => ctx.setState(removeCategory(action.id)),
        error: error => ctx.patchState({error: convertError(error)}),
      }),
      finalize(() => ctx.patchState({loading: false})),
    );
  }

  @Action(ReorderCategories, {cancelUncompleted: true})
  reorderCategories(ctx: StateContext<CategoryStateModel>, action: ReorderCategories) {
    ctx.patchState({
      error: undefined,
      loading: true,
    })

    return this.productManagementService.putCategoryOrder({defineCategoryOrderRequest: {categories: action.ids}}).pipe(
      tap({
        next: categories => ctx.patchState({categories}),
        error: error => ctx.patchState({error: convertError(error)}),
      }),
      finalize(() => ctx.patchState({loading: false})),
    );
  }
}
