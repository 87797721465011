import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext, StateOperator, StateToken} from "@ngxs/store";
import {RequestState, RequestStateSelectors, ResetErrors} from "./common";
import {TaxCategory} from "../api/model/taxCategory";
import {doRequest} from "../services/util";
import {CheckoutTaxCategoriesHttpService} from "../api/api/checkoutTaxCategories.http.service";

export interface TaxCategoryStateModel extends RequestState {
  categories: TaxCategory[] | undefined;
}

export class LoadTaxCategories {
  static readonly type = '[Tax-Categories] Load tax categories';
}

export class CreateTaxCategory {
  static readonly type = '[Tax-Categories] Create tax category';

  constructor(
    readonly name: string,
  ) {
  }
}

export class DeleteTaxCategory {
  static readonly type = '[Tax-Categories] Delete tax category';

  constructor(
    readonly id: string,
  ) {
  }
}

export const TAX_CATEGORY_STATE_TOKEN = new StateToken<TaxCategoryStateModel>('taxCategoryState');

const addCategory: ((category: TaxCategory) => StateOperator<TaxCategoryStateModel>) = (category) => {
  return (state: Readonly<TaxCategoryStateModel>) => {
    return {
      ...state,
      categories: [
        ...state.categories || [],
        category
      ]
    }
  }
}

const removeCategory: ((id: string) => StateOperator<TaxCategoryStateModel>) = (id) => {
  return (state: Readonly<TaxCategoryStateModel>) => {
    return {
      ...state,
      categories: state.categories?.filter(elem => elem.id !== id)
    }
  }
}

@State({
  name: TAX_CATEGORY_STATE_TOKEN,
  defaults: {
    categories: undefined,
    errorByRequest: {},
    loadingByRequest: {},
  }
})
@Injectable()
export class TaxCategoryState {

  static readonly REQUESTS = new RequestStateSelectors(TAX_CATEGORY_STATE_TOKEN)

  constructor(
    private readonly taxCategoriesService: CheckoutTaxCategoriesHttpService,
  ) {
  }

  @Selector()
  static categories(state: TaxCategoryStateModel) {
    return state.categories;
  }

  @Action(ResetErrors, {cancelUncompleted: true})
  resetErrors(ctx: StateContext<TaxCategoryStateModel>) {
    ctx.patchState({errorByRequest: {}})
  }

  @Action(LoadTaxCategories, {cancelUncompleted: true})
  loadCategories(ctx: StateContext<TaxCategoryStateModel>, action: LoadTaxCategories) {
    return doRequest({
      ctx,
      type: 'total',
      id: '',
      obs$: this.taxCategoriesService.getTaxCategories(),
      next: categories => ctx.patchState({categories}),
    })
  }

  @Action(CreateTaxCategory, {cancelUncompleted: false})
  createCategory(ctx: StateContext<TaxCategoryStateModel>, action: CreateTaxCategory) {
    return doRequest({
      ctx,
      type: 'create',
      id: '',
      obs$: this.taxCategoriesService.postTaxCategory({
        createTaxCategoryRequest: {
          name: action.name,
        }
      }),
      next: category => ctx.setState(addCategory(category)),
    });
  }

  @Action(DeleteTaxCategory, {cancelUncompleted: false})
  deleteCategory(ctx: StateContext<TaxCategoryStateModel>, action: DeleteTaxCategory) {
    return doRequest({
      ctx,
      type: 'delete',
      id: '',
      obs$: this.taxCategoriesService.deleteTaxCategories({taxCategoryId: action.id}),
      next: () => ctx.setState(removeCategory(action.id)),
    });
  }
}
