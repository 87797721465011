import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext, StateToken} from "@ngxs/store";
import {finalize, tap} from "rxjs";
import {addError, RequestState, RequestStateSelectors, ResetErrors} from "./common";
import {ProgressBarMode} from "@angular/material/progress-bar";
import {HttpEventType} from "@angular/common/http";
import {TitleImageHttpService} from "../api/api/titleImage.http.service";


export interface AdminSettingsStateModel extends RequestState {
  uploadProgress: { mode: ProgressBarMode, value: number } | undefined;
}

export class UploadTitleImage {
  static readonly type = '[Admin Settings] Upload title image';

  constructor(
    public readonly image: Blob,
  ) {
  }
}

export const ADMIN_SETTINGS_STATE_TOKEN = new StateToken<AdminSettingsStateModel>('adminSettingsState');

@State({
  name: ADMIN_SETTINGS_STATE_TOKEN,
  defaults: {
    errorByRequest: {},
    loadingByRequest: {},
    uploadProgress: undefined,
  }
})
@Injectable()
export class AdminSettingsState {

  static readonly REQUESTS = new RequestStateSelectors(ADMIN_SETTINGS_STATE_TOKEN)

  constructor(
    private readonly titleImageService: TitleImageHttpService,
  ) {
  }

  @Selector()
  static uploadProgress(state: AdminSettingsStateModel) {
    return state.uploadProgress;
  }

  @Action(ResetErrors)
  resetErrors(ctx: StateContext<AdminSettingsStateModel>) {
    ctx.patchState({errorByRequest: {}})
  }

  @Action(UploadTitleImage, {cancelUncompleted: false})
  editItemImage(ctx: StateContext<AdminSettingsStateModel>, action: UploadTitleImage) {
    const reqId = `upload:title-image`;
    ctx.patchState({uploadProgress: {mode: 'indeterminate', value: 0}})
    ctx.setState(addError(reqId, undefined))

    return this.titleImageService.updateTitleImage({image: action.image}, 'events', true).pipe(
      tap({
        next: event => {
          if (event.type == HttpEventType.UploadProgress && event.total) {
            ctx.patchState({
              uploadProgress: {
                mode: 'determinate',
                value: Math.round(100 * (event.loaded / event.total))
              }
            });
          }
        },
        error: error => ctx.setState(addError(reqId, error)),
      }),
      finalize(() => ctx.patchState({uploadProgress: undefined})),
    );
  }
}
