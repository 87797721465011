import {ProductWithItems} from "../api/model/productWithItems";
import {StateOperator} from "@ngxs/store";
import {Image} from "../api/model/image";
import {ProductPage} from "../api/model/productPage";
import {ProductState, ProductStateModel} from "./product-state";
import {Item} from "../api/model/item";
import {Product} from "../api/model/product";

export const addProduct: ((product: Product | ProductWithItems) => StateOperator<ProductStateModel>) = product => {
  return (state: Readonly<ProductStateModel>) => {
    const existing = state.productById[product.id];
    return {
      ...state,
      productById: {
        ...state.productById,
        [product.id]: 'items' in product ? product : {...product, items: existing?.items || []}
      },
      productIdBySlug: {
        ...state.productIdBySlug,
        [product.slug]: product.id
      },
    }
  }
}

export const removeProductImage: ((productId: string, imageId: string) => StateOperator<ProductStateModel>) = (productId, imageId) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          images: product.images.filter(elem => elem.id !== imageId)
        }
      },
    } : state;
  }
}

export const removeItemImage: ((productId: string, itemId: string, imageId: string) => StateOperator<ProductStateModel>) = (productId, itemId, imageId) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          items: product.items.map(elem => {
            if (elem.id === itemId) {
              return {
                ...elem,
                images: elem.images.filter(elem => elem.id !== imageId)
              }
            } else {
              return elem
            }
          })
        }
      },
    } : state;
  }
}

export const addProductImage: ((productId: string, image: Image) => StateOperator<ProductStateModel>) = (productId, image) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          images: [
            ...product.images,
            image
          ]
        }
      },
    } : state;
  }
}

export const addItemImage: ((productId: string, itemId: string, image: Image) => StateOperator<ProductStateModel>) = (productId, itemId, image) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          items: product.items.map(elem => {
            if (elem.id === itemId) {
              return {
                ...elem,
                images: [
                  ...elem.images,
                  image
                ]
              }
            } else {
              return elem
            }
          })
        }
      },
    } : state;
  }
}

export const addItem: ((productId: string, item: Item) => StateOperator<ProductStateModel>) = (productId, item) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    if (!product) {
      return state;
    }
    const exists = product.items.find(elem => elem.id === item.id);
    return {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          items: exists ? product.items.map(elem => elem.id === item.id ? item : elem) : [...product.items, item]
        }
      },
    };
  }
}

export const replaceProductImages: ((productId: string, images: Image[]) => StateOperator<ProductStateModel>) = (productId, images) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          images: images
        }
      },
    } : state;
  }
}

export const replaceItemImages: ((productId: string, itemId: string, images: Image[]) => StateOperator<ProductStateModel>) = (productId, itemId, images) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          items: product.items.map(elem => {
            if (elem.id === itemId) {
              return {
                ...elem,
                images: images
              }
            } else {
              return elem
            }
          })
        }
      },
    } : state;
  }
}

export const replaceProductImage: ((productId: string, image: Image) => StateOperator<ProductStateModel>) = (productId, image) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          images: product.images.map(elem => elem.id === image.id ? image : elem)
        }
      },
    } : state;
  }
}

export const replaceItemImage: ((productId: string, itemId: string, image: Image) => StateOperator<ProductStateModel>) = (productId, itemId, image) => {
  return (state: Readonly<ProductStateModel>) => {
    const product = state.productById[productId];
    return product ? {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...product,
          items: product.items.map(elem => {
            if (elem.id === itemId) {
              return {
                ...elem,
                images: elem.images.map(img => img.id === image.id ? image : img)
              }
            } else {
              return elem
            }
          })
        }
      },
    } : state;
  }
}

export const removeProduct: ((productId: string) => StateOperator<ProductStateModel>) = productId => {
  return (state: Readonly<ProductStateModel>) => {
    return {
      ...state,
      productPages: Object.fromEntries(Object.entries(state.productPages)
        .filter(([_, page]) => !!page)
        .map(([key, page]) => [key, {
          ...page!,
          content: [...page!.content.map(elements => elements.filter(id => id !== productId))]
        }])),
      productById: {
        ...state.productById,
        [productId]: undefined,
      },
      productIdBySlug: Object.fromEntries(
        Object.entries(state.productIdBySlug).filter(([_, id]) => id && id !== productId)
      ),
    }
  }
}

export const removeItem: ((productId: string, itemId: string) => StateOperator<ProductStateModel>) = (productId, itemId) => {
  return (state: Readonly<ProductStateModel>) => {
    const existing = state.productById[productId];
    if (!existing) {
      return state;
    }
    return {
      ...state,
      productById: {
        ...state.productById,
        [productId]: {
          ...existing,
          items: existing.items.filter(item => item.id !== itemId)
        },
      },
    }
  }
}

export const addPage: ((params: string, page: ProductPage) => StateOperator<ProductStateModel>) = (params, page) => {
  return (state: Readonly<ProductStateModel>) => {
    return {
      ...state,
      productPages: {
        ...state.productPages,
        [params]: {
          totalPages: page.totalPages,
          totalElements: page.totalElements,
          content: [
            ...(state.productPages[params]?.content || []),
            page.content.map((product: ProductWithItems) => product.id)
          ],
        }
      },
      productById: {
        ...state.productById,
        ...Object.fromEntries(page.content.map((product: ProductWithItems) => [product.id, product]))
      },
      productIdBySlug: {
        ...state.productIdBySlug,
        ...Object.fromEntries(page.content.map((product: ProductWithItems) => [product.slug, product.id]))
      },
    }
  }
}

export const addProducts: ((products: ProductWithItems[]) => StateOperator<ProductStateModel>) = (products) => {
  return (state: Readonly<ProductStateModel>) => {
    return {
      ...state,
      productById: {
        ...state.productById,
        ...Object.fromEntries(products.map((product: ProductWithItems) => [product.id, product]))
      },
      productIdBySlug: {
        ...state.productIdBySlug,
        ...Object.fromEntries(products.map((product: ProductWithItems) => [product.slug, product.id]))
      },
    }
  }
}
