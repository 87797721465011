import {RequestState, RequestStateSelectors, ResetErrors} from "./common";
import {ShippingOption} from "../api/model/shippingOption";
import {Action, Selector, State, StateContext, StateOperator, StateToken} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {doRequest} from "../services/util";
import {EditShippingOptionRequest} from "../api/model/editShippingOptionRequest";
import {CreateShippingOptionRequest} from "../api/model/createShippingOptionRequest";
import {CheckoutShippingOptionsHttpService} from "../api/api/checkoutShippingOptions.http.service";

export interface ShippingOptionStateModel extends RequestState {
  options: ShippingOption[] | undefined;
}

export class LoadShippingOptions {
  static readonly type = '[Shipping Options] Load shipping options';

  constructor(
    readonly force: boolean,
  ) {
  }
}

export class CreateShippingOption {
  static readonly type = '[Shipping Options] Create shipping option';

  constructor(
    readonly request: CreateShippingOptionRequest,
  ) {
  }
}

export class EditShippingOption {
  static readonly type = '[Shipping Options] Edit shipping option';

  constructor(
    readonly id: string,
    readonly request: EditShippingOptionRequest,
  ) {
  }
}

export class DeleteShippingOption {
  static readonly type = '[Shipping Options] Delete shipping option';

  constructor(
    readonly id: string,
  ) {
  }
}

const addOption: ((option: ShippingOption) => StateOperator<ShippingOptionStateModel>) = (option) => {
  return (state: Readonly<ShippingOptionStateModel>) => {
    return {
      ...state,
      options: [
        ...state.options?.filter(e => e.id !== option.id) || [],
        option
      ].sort((a, b) => a.priceAmount - b.priceAmount)
    }
  }
}

const removeOption: ((id: string) => StateOperator<ShippingOptionStateModel>) = (id) => {
  return (state: Readonly<ShippingOptionStateModel>) => {
    return {
      ...state,
      options: state.options?.filter(elem => elem.id !== id)
    }
  }
}

export const SHIPPING_OPTION_STATE_TOKEN = new StateToken<ShippingOptionStateModel>('shippingOptionState');

@State({
  name: SHIPPING_OPTION_STATE_TOKEN,
  defaults: {
    options: undefined,
    errorByRequest: {},
    loadingByRequest: {},
  }
})
@Injectable()
export class ShippingOptionState {

  static readonly REQUESTS = new RequestStateSelectors(SHIPPING_OPTION_STATE_TOKEN)

  constructor(
    private readonly shippingOptionService: CheckoutShippingOptionsHttpService,
  ) {
  }

  @Selector()
  static options(state: ShippingOptionStateModel) {
    return state.options;
  }

  @Action(ResetErrors, {cancelUncompleted: true})
  resetErrors(ctx: StateContext<ShippingOptionStateModel>) {
    ctx.patchState({errorByRequest: {}})
  }

  @Action(LoadShippingOptions, {cancelUncompleted: true})
  loadOptions(ctx: StateContext<ShippingOptionStateModel>, action: LoadShippingOptions) {
    if (!action.force && ctx.getState().options) {
      return
    }
    return doRequest({
      ctx,
      type: 'total',
      id: '',
      obs$: this.shippingOptionService.getShippingOptions(),
      next: options => ctx.patchState({options}),
    })
  }

  @Action(CreateShippingOption, {cancelUncompleted: false})
  create(ctx: StateContext<ShippingOptionStateModel>, action: CreateShippingOption) {
    return doRequest({
      ctx,
      type: 'create',
      id: '',
      obs$: this.shippingOptionService.postShippingOption({
        createShippingOptionRequest: action.request
      }),
      next: option => ctx.setState(addOption(option)),
    });
  }

  @Action(EditShippingOption, {cancelUncompleted: false})
  edit(ctx: StateContext<ShippingOptionStateModel>, action: EditShippingOption) {
    return doRequest({
      ctx,
      type: 'edit',
      id: action.id,
      obs$: this.shippingOptionService.putShippingOption({
        shippingOptionId: action.id,
        editShippingOptionRequest: action.request
      }),
      next: option => ctx.setState(addOption(option)),
    });
  }

  @Action(DeleteShippingOption, {cancelUncompleted: false})
  delete(ctx: StateContext<ShippingOptionStateModel>, action: DeleteShippingOption) {
    return doRequest({
      ctx,
      type: 'delete',
      id: action.id,
      obs$: this.shippingOptionService.deleteShippingOption({
        shippingOptionId: action.id,
      }),
      next: () => ctx.setState(removeOption(action.id)),
    });
  }
}
