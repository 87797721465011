import {ProductWithItems} from "../api/model/productWithItems";
import {Injectable} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext, StateToken} from "@ngxs/store";
import {map} from "rxjs";
import {ProductsHttpService} from "../api/api/products.http.service";
import {RequestState, RequestStateSelectors, ResetErrors} from "./common";
import {ProductManagementHttpService} from "../api/api/productManagement.http.service";
import {Product} from "../api/model/product";
import {DateTime} from "luxon";
import {addItem, addPage, addProduct, addProducts, removeItem, removeProduct} from "./product-state-functions";
import {
  CreateItem,
  CreateProduct,
  DeleteItem,
  DeleteItemImage,
  DeleteProduct,
  DeleteProductImage,
  EditItem,
  EditItemImage,
  EditProduct,
  EditProductImage,
  GetProductById,
  GetProductBySlug,
  InsertProducts,
  LoadProductPage,
  ReorderItemImages,
  ReorderProductImages,
  UploadItemImage,
  UploadProductImage
} from "./product-state-actions";
import {ProgressBarMode} from "@angular/material/progress-bar";
import {Item} from "../api/model/item";
import {ImageService} from "../services/image.service";
import {doRequest, filterNulls} from "../services/util";

export interface ProductPages {
  totalElements: number;
  totalPages: number;
  content: string[][];
}

export interface ProductStateModel extends RequestState {
  createdProduct: Product | undefined;
  productById: { [id: string]: ProductWithItems | undefined };
  productIdBySlug: { [id: string]: string | undefined };
  productPages: { [params: string]: ProductPages | undefined };
  uploadProgress: { mode: ProgressBarMode, value: number } | undefined;
}

export type ProductAndItem = ProductWithItems & { item: Item };

export const PRODUCT_STATE_TOKEN = new StateToken<ProductStateModel>('productState');

@State({
  name: PRODUCT_STATE_TOKEN,
  defaults: {
    createdProduct: undefined,
    loadingByRequest: {},
    errorByRequest: {},
    productById: {},
    productIdBySlug: {},
    productPages: {},
    uploadProgress: undefined,
  }
})
@Injectable()
export class ProductState {

  static readonly REQUESTS = new RequestStateSelectors(PRODUCT_STATE_TOKEN)

  constructor(
    private readonly productsService: ProductsHttpService,
    private readonly productManagementService: ProductManagementHttpService,
    private readonly imageService: ImageService,
  ) {
  }

  @Selector()
  static created(state: ProductStateModel) {
    return state.createdProduct;
  }

  @Selector()
  static uploadProgress(state: ProductStateModel) {
    return state.uploadProgress;
  }

  static items(ids: { [productId: string]: string[] }): (state: ProductStateModel) => {
    [productId: string]: { [itemId: string]: ProductAndItem }
  } {
    return createSelector([ProductState], (state: ProductStateModel) => {
      return Object.fromEntries(
        filterNulls(
          Object.entries(ids)
            .map(([productId, itemIds]) => {
              const product = state.productById[productId]
              if (!product) {
                return undefined;
              }
              return [
                productId,
                Object.fromEntries(
                  filterNulls(
                    itemIds.map(itemId => {
                      const item = product.items.find(item => item.id === itemId);
                      if (!item) {
                        return undefined;
                      }
                      return [itemId, {...product, item}];
                    })
                  )
                )
              ]
            })
        )
      )
    });
  }

  static bySlug(slug: string): (state: ProductStateModel) => ProductWithItems | undefined {
    return createSelector([ProductState], (state: ProductStateModel) => {
      const id = state.productIdBySlug[slug];
      return id ? state.productById[id] : undefined;
    });
  }

  static byId(id: string) {
    return createSelector([ProductState], (state: ProductStateModel) => {
      return state.productById[id];
    });
  }

  static byParams(params: string) {
    return createSelector([ProductState], (state: ProductStateModel) => {
      return state.productPages[params];
    });
  }

  @Action(ResetErrors, {cancelUncompleted: true})
  resetErrors(ctx: StateContext<ProductStateModel>) {
    ctx.patchState({errorByRequest: {}})
  }

  @Action(GetProductById, {cancelUncompleted: true})
  getProductById(ctx: StateContext<ProductStateModel>, action: GetProductById) {
    if (ctx.getState().productById[action.id]) {
      return;
    }
    return doRequest({
      ctx,
      type: 'get',
      id: action.id,
      obs$: this.productsService.getProduct({productId: action.id}),
      next: product => ctx.setState(addProduct(product))
    })
  }

  @Action(CreateProduct, {cancelUncompleted: false})
  createProduct(ctx: StateContext<ProductStateModel>, action: CreateProduct) {
    ctx.patchState({createdProduct: undefined});
    return doRequest({
      ctx,
      type: 'create',
      id: '',
      obs$: this.productManagementService.createProduct({createProductRequest: action.request}),
      next: product => {
        ctx.setState(addProduct({...product, items: []}));
        ctx.patchState({productPages: {}, createdProduct: product});
      }
    })
  }

  @Action(EditProduct, {cancelUncompleted: false})
  editProduct(ctx: StateContext<ProductStateModel>, action: EditProduct) {
    return doRequest({
      ctx,
      type: 'edit',
      id: action.productId,
      obs$: this.productManagementService.editProduct({
        productId: action.productId,
        editProductRequest: action.request
      }),
      next: product => ctx.setState(addProduct(product))
    })
  }

  @Action(CreateItem, {cancelUncompleted: false})
  createItem(ctx: StateContext<ProductStateModel>, action: CreateItem) {
    return doRequest({
      ctx,
      type: 'create',
      id: '',
      obs$: this.productManagementService.addItem({productId: action.productId, addItemRequest: action.request}),
      next: item => {
        const product = ctx.getState().productById[item.productType.id]!
        if (product.items.length === 0 && product.images.length > 0) {
          ctx.patchState({productPages: {}});
        }
        ctx.setState(addItem(action.productId, {...item, productType: item.productType.id}))
      }
    })
  }

  @Action(EditItem, {cancelUncompleted: false})
  editItem(ctx: StateContext<ProductStateModel>, action: EditItem) {
    return doRequest({
      ctx,
      type: 'edit',
      id: action.productId,
      obs$: this.productManagementService.editItem({
        productId: action.productId,
        itemId: action.itemId,
        editItemRequest: action.request
      }),
      next: item => ctx.setState(addItem(action.productId, {...item, productType: item.productType.id}))
    })
  }

  @Action(DeleteProduct, {cancelUncompleted: false})
  deleteProduct(ctx: StateContext<ProductStateModel>, action: DeleteProduct) {
    return doRequest({
      ctx,
      type: 'delete',
      id: action.productId,
      obs$: this.productManagementService.deleteProduct({productId: action.productId}),
      next: () => ctx.setState(removeProduct(action.productId))
    })
  }

  @Action(DeleteItem, {cancelUncompleted: false})
  deleteItem(ctx: StateContext<ProductStateModel>, action: DeleteItem) {
    return doRequest({
      ctx,
      type: 'delete',
      id: action.itemId,
      obs$: this.productManagementService.deleteItem({productId: action.productId, itemId: action.itemId}),
      next: () => ctx.setState(removeItem(action.productId, action.itemId))
    })
  }

  @Action(GetProductBySlug, {cancelUncompleted: true})
  getProductBySlug(ctx: StateContext<ProductStateModel>, action: GetProductBySlug) {
    const state = ctx.getState();
    const id = state.productIdBySlug[action.slug];
    if (id && state.productById[id]) {
      return;
    }

    return doRequest({
      ctx,
      type: 'slug',
      id: action.slug,
      obs$: this.productsService.getProducts({
        size: 1,
        slug: action.slug,
        includeOutOfStock: true,
        includeIncomplete: action.includeIncomplete
      }).pipe(
        map(page => {
          if (page.content.length == 1) {
            return page.content[0];
          }
          throw {
            error: {
              timestamp: DateTime.now().toString(),
              code: 'NOT_FOUND',
              message: 'Product by slug not found.',
              rejectedArguments: [],
              args: {slug: action.slug},
            }
          }
        }),
      ),
      next: product => ctx.setState(addProduct(product))
    })
  }

  @Action(LoadProductPage, {cancelUncompleted: true})
  getProductPage(ctx: StateContext<ProductStateModel>, action: LoadProductPage) {
    if ((ctx.getState().productPages[action.toString()]?.content?.length || 0) > action.params.page) {
      return;
    }

    return doRequest({
      ctx,
      type: 'page',
      id: action.toString(),
      obs$: this.productsService.getProducts({
        page: action.params.page,
        size: 20,
        sort: action.params.sort ? [action.params.sort] : undefined,
        category: action.params.category,
        slug: action.params.slug,
        deleted: action.params.deleted,
        includeIncomplete: action.params.includeIncomplete,
        includeOutOfStock: action.params.includeOutOfStock,
      }),
      next: page => {
        ctx.setState(addPage(action.toString(), page))
      }
    })
  }

  @Action(UploadProductImage, {cancelUncompleted: false})
  uploadProductImage(ctx: StateContext<ProductStateModel>, action: UploadProductImage) {
    return this.imageService.uploadProductImage(ctx, action)
  }

  @Action(UploadItemImage, {cancelUncompleted: false})
  uploadItemImage(ctx: StateContext<ProductStateModel>, action: UploadItemImage) {
    return this.imageService.uploadItemImage(ctx, action)
  }

  @Action(DeleteProductImage, {cancelUncompleted: false})
  deleteProductImage(ctx: StateContext<ProductStateModel>, action: DeleteProductImage) {
    return this.imageService.deleteProductImage(ctx, action)
  }

  @Action(DeleteItemImage, {cancelUncompleted: false})
  deleteItemImage(ctx: StateContext<ProductStateModel>, action: DeleteItemImage) {
    return this.imageService.deleteItemImage(ctx, action)
  }

  @Action(ReorderProductImages, {cancelUncompleted: false})
  reorderProductImages(ctx: StateContext<ProductStateModel>, action: ReorderProductImages) {
    return this.imageService.reorderProductImages(ctx, action)
  }

  @Action(ReorderItemImages, {cancelUncompleted: false})
  reorderItemImages(ctx: StateContext<ProductStateModel>, action: ReorderItemImages) {
    return this.imageService.reorderItemImages(ctx, action)
  }

  @Action(EditProductImage, {cancelUncompleted: false})
  editProductImage(ctx: StateContext<ProductStateModel>, action: EditProductImage) {
    return this.imageService.editProductImage(ctx, action)
  }

  @Action(EditItemImage, {cancelUncompleted: false})
  editItemImage(ctx: StateContext<ProductStateModel>, action: EditItemImage) {
    return this.imageService.editItemImage(ctx, action)
  }

  @Action(InsertProducts, {cancelUncompleted: false})
  insertProducts(ctx: StateContext<ProductStateModel>, action: InsertProducts) {
    ctx.setState(addProducts(action.products))
  }
}
