import {RequestState, RequestStateSelectors, ResetErrors} from "./common";
import {Action, Selector, State, StateContext, StateOperator, StateToken} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {doRequest} from "../services/util";
import {DestinationCountry} from "../api/model/destinationCountry";
import {CreateDestinationCountryRequest} from "../api/model/createDestinationCountryRequest";
import {EditDestinationCountryRequest} from "../api/model/editDestinationCountryRequest";
import {CheckoutDestinationCountriesHttpService} from "../api/api/checkoutDestinationCountries.http.service";

export interface DestinationCountryStateModel extends RequestState {
  countries: DestinationCountry[] | undefined;
}

export class LoadDestinationCountries {
  static readonly type = '[Destination Countries] Load destination country';

  constructor(readonly force: boolean) {
  }
}

export class CreateDestinationCountry {
  static readonly type = '[Destination Countries] Create destination country';

  constructor(
    readonly request: CreateDestinationCountryRequest,
  ) {
  }
}

export class EditDestinationCountry {
  static readonly type = '[Destination Countries] Edit destination country';

  constructor(
    readonly id: string,
    readonly request: EditDestinationCountryRequest,
  ) {
  }
}

export class DeleteDestinationCountry {
  static readonly type = '[Destination Countries] Delete destination country';

  constructor(
    readonly id: string,
  ) {
  }
}

const addCountry: ((country: DestinationCountry) => StateOperator<DestinationCountryStateModel>) = (country) => {
  return (state: Readonly<DestinationCountryStateModel>) => {
    return {
      ...state,
      countries: [
        ...state.countries
          ?.filter(e => e.id !== country.id)
          ?.map(e => {
            if (country.isHomeCountry) {
              return {...e, isHomeCountry: false}
            }
            return e;
          }) || [],
        country
      ].sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
    }
  }
}

const removeCountry: ((id: string) => StateOperator<DestinationCountryStateModel>) = (id) => {
  return (state: Readonly<DestinationCountryStateModel>) => {
    return {
      ...state,
      countries: state.countries?.filter(elem => elem.id !== id)
    }
  }
}

export const DESTINATION_COUNTRY_STATE_TOKEN = new StateToken<DestinationCountryStateModel>('destinationCountryState');

@State({
  name: DESTINATION_COUNTRY_STATE_TOKEN,
  defaults: {
    countries: undefined,
    errorByRequest: {},
    loadingByRequest: {},
  }
})
@Injectable()
export class DestinationCountryState {

  static readonly REQUESTS = new RequestStateSelectors(DESTINATION_COUNTRY_STATE_TOKEN)

  constructor(
    private readonly destinationCountryService: CheckoutDestinationCountriesHttpService,
  ) {
  }

  @Selector()
  static countries(state: DestinationCountryStateModel) {
    return state.countries;
  }

  @Action(ResetErrors, {cancelUncompleted: true})
  resetErrors(ctx: StateContext<DestinationCountryStateModel>) {
    ctx.patchState({errorByRequest: {}})
  }

  @Action(LoadDestinationCountries, {cancelUncompleted: true})
  load(ctx: StateContext<DestinationCountryStateModel>, action: LoadDestinationCountries) {
    if (!action.force && ctx.getState().countries) {
      return
    }
    return doRequest({
      ctx,
      type: 'total',
      id: '',
      obs$: this.destinationCountryService.getDestinationCountries(),
      next: countries => ctx.patchState({countries}),
    })
  }

  @Action(CreateDestinationCountry, {cancelUncompleted: false})
  create(ctx: StateContext<DestinationCountryStateModel>, action: CreateDestinationCountry) {
    return doRequest({
      ctx,
      type: 'create',
      id: '',
      obs$: this.destinationCountryService.postDestinationCountry({
        createDestinationCountryRequest: action.request
      }),
      next: country => ctx.setState(addCountry(country)),
    });
  }

  @Action(EditDestinationCountry, {cancelUncompleted: false})
  edit(ctx: StateContext<DestinationCountryStateModel>, action: EditDestinationCountry) {
    return doRequest({
      ctx,
      type: 'edit',
      id: action.id,
      obs$: this.destinationCountryService.putDestinationCountry({
        destinationCountryId: action.id,
        editDestinationCountryRequest: action.request
      }),
      next: country => ctx.setState(addCountry(country)),
    });
  }

  @Action(DeleteDestinationCountry, {cancelUncompleted: false})
  delete(ctx: StateContext<DestinationCountryStateModel>, action: DeleteDestinationCountry) {
    return doRequest({
      ctx,
      type: 'delete',
      id: action.id,
      obs$: this.destinationCountryService.deleteDestinationCountry({
        destinationCountryId: action.id,
      }),
      next: () => ctx.setState(removeCountry(action.id)),
    });
  }
}
