import {Injectable} from '@angular/core';
import {ProductManagementHttpService} from "../api/api/productManagement.http.service";
import {StateContext} from "@ngxs/store";
import {
  DeleteItemImage,
  DeleteProductImage,
  EditItemImage,
  EditProductImage,
  ReorderItemImages,
  ReorderProductImages,
  UploadItemImage,
  UploadProductImage
} from "../state/product-state-actions";
import {
  addItemImage,
  addProductImage,
  removeItemImage,
  removeProductImage,
  replaceItemImage,
  replaceItemImages,
  replaceProductImage,
  replaceProductImages
} from "../state/product-state-functions";
import {finalize, Observable, tap} from "rxjs";
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {Image} from "../api/model/image";
import {addError} from "../state/common";
import {ProductStateModel} from "../state/product-state";
import {doRequest} from "./util";

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private readonly productManagementService: ProductManagementHttpService,
  ) {
  }

  deleteProductImage(ctx: StateContext<ProductStateModel>, action: DeleteProductImage) {
    return doRequest({
      ctx,
      type: 'delete',
      id: action.imageId,
      obs$: this.productManagementService.deleteProductImage(action),
      next: () => ctx.setState(removeProductImage(action.productId, action.imageId))
    })
  }

  deleteItemImage(ctx: StateContext<ProductStateModel>, action: DeleteItemImage) {
    return doRequest({
      ctx,
      type: 'delete',
      id: action.imageId,
      obs$: this.productManagementService.deleteItemImage(action),
      next: () => ctx.setState(removeItemImage(action.productId, action.itemId, action.imageId))
    })
  }

  editProductImage(ctx: StateContext<ProductStateModel>, action: EditProductImage) {
    return doRequest({
      ctx,
      type: 'edit',
      id: action.imageId,
      obs$: this.productManagementService.editProductImage({
        productId: action.productId,
        imageId: action.imageId,
        editImageRequest: action.request
      }),
      next: img => ctx.setState(replaceProductImage(action.productId, img))
    })
  }

  editItemImage(ctx: StateContext<ProductStateModel>, action: EditItemImage) {
    return doRequest({
      ctx,
      type: 'edit',
      id: action.imageId,
      obs$: this.productManagementService.editItemImage({
        productId: action.productId,
        itemId: action.itemId,
        imageId: action.imageId,
        editImageRequest: action.request
      }),
      next: img => ctx.setState(replaceItemImage(action.productId, action.itemId, img))
    })
  }

  reorderProductImages(ctx: StateContext<ProductStateModel>, action: ReorderProductImages) {
    return doRequest({
      ctx,
      type: 'reorder',
      id: action.productId,
      obs$: this.productManagementService.putImageOrder({
        productId: action.productId,
        defineImageOrderRequest: {images: action.imageIds}
      }),
      next: images => ctx.setState(replaceProductImages(action.productId, images))
    })
  }

  reorderItemImages(ctx: StateContext<ProductStateModel>, action: ReorderItemImages) {
    return doRequest({
      ctx,
      type: 'reorder',
      id: action.productId,
      obs$: this.productManagementService.putItemImageOrder({
        productId: action.productId,
        itemId: action.itemId,
        defineImageOrderRequest: {images: action.imageIds}
      }),
      next: images => ctx.setState(replaceItemImages(action.productId, action.itemId, images))
    })
  }

  uploadProductImage(ctx: StateContext<ProductStateModel>, action: UploadProductImage) {
    return this.uploadImage(
      ctx,
      action.productId,
      this.productManagementService.uploadImage(
        action,
        'events',
        true
      ),
      image => {
        const product = ctx.getState().productById[action.productId]!
        if (product.items.length > 0 && product.images.length === 0) {
          ctx.patchState({productPages: {}});
        }
        ctx.setState(addProductImage(action.productId, image))
      }
    )
  }

  uploadItemImage(ctx: StateContext<ProductStateModel>, action: UploadItemImage) {
    return this.uploadImage(
      ctx,
      action.itemId,
      this.productManagementService.uploadItemImage(
        action,
        'events',
        true
      ),
      image => ctx.setState(addItemImage(action.productId, action.itemId, image))
    )
  }

  private uploadImage(
    ctx: StateContext<ProductStateModel>,
    id: string,
    obs$: Observable<HttpEvent<Image>>,
    after: (response: Image) => void,
  ) {
    const reqId = `upload:${id}`;
    ctx.patchState({uploadProgress: {mode: 'indeterminate', value: 0}})
    ctx.setState(addError(reqId, undefined))

    return obs$.pipe(
      tap({
        next: event => {
          if (event.type == HttpEventType.UploadProgress && event.total) {
            ctx.patchState({
              uploadProgress: {
                mode: 'determinate',
                value: Math.round(100 * (event.loaded / event.total))
              }
            });
          } else if (event.type == HttpEventType.Response) {
            after(event.body!)
          }
        },
        error: error => ctx.setState(addError(reqId, error)),
      }),
      finalize(() => ctx.patchState({uploadProgress: undefined})),
    );
  }
}
